import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
// CSS FILE LINK
import "./App.css";
import logo from './Log.png'

function App() {
  const redirectToWhatsApp = () => {
    window.location.href = "https://chat.whatsapp.com/KWesGcRAtKFCOogBADRKz2";
  };
  return (
    <section className="container">
      <div className="clock_content">
        <div className="">
          <img src={logo} />
          <h1 className='clock'>Afelit</h1>
        </div>
        <h1>We are almost there</h1>
        <h3>Stay tuned for something amazing.</h3>
        {/* <FlipClockCountdown
          className='flip-clock'
          to={new Date().getTime() + 24 * 3600 * 1000 + 5000}
          labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
          duration={0.5}
        /> */}
        <button  onClick={redirectToWhatsApp} className="button">Notify Me</button>
      </div>
    </section>
  );
}

export default App;
